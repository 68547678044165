import React, { Component } from 'react';
import './MainLayout.css';
import { Layout, Menu, Row, Icon } from 'antd/lib/';
import Campaign from './mainRows/Campaign.js';
import Issues from './mainRows/Issues.js';
import Action from './mainRows/Action.js';
import Supporters from './mainRows/Supporters.js'
const { Header, Content, Footer } = Layout;

class MainLayout extends Component {
  render() {
    return (
      <Layout className="layout">
        <Header>
          <div className='logo' />
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item style={{float: 'right'}} key="1"><a target='_blank' rel='noopener noreferrer' href="https://www.youtube.com/channel/UC-diFT4pyvQxCXfsaGAoqGA"><Icon type="youtube"  /></a></Menu.Item>
            <Menu.Item style={{float: 'right'}} key="2"><a target='_blank' rel='noopener noreferrer' href="https://www.instagram.com/ye4us/"><Icon type="instagram"  /></a></Menu.Item>
            <Menu.Item style={{float: 'right'}} key="3"><a target='_blank' rel='noopener noreferrer' href="https://twitter.com/ye4us"><Icon type="twitter"  /></a></Menu.Item>
          </Menu>
        </Header>
        <Content>
          <header>
            <h1><span>ye</span>4<span>us</span></h1>
          </header>
          <div>
            <Campaign />
            <Issues />
            <Action />
            <Supporters />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <Row>
            <p id="footer">ye4us © 2018</p> 
            <p>in anticipation and support of <s>2024</s> 2020</p>
            <p>ye4us is not affiliated with Kanye West...yet.</p>
            <p>ye4us</p>
            <p>ye for us</p>
            <p>Ye for U.S. President</p>
          </Row>
          <Row>
            <a href="https://twitter.com/ye4us?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-size='large' data-show-count="false">Follow @ye4us</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
          </Row>
        </Footer>
      </Layout>
    )
  }
}
export default MainLayout;
