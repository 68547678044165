import React, { Component } from 'react';
import { Tweet } from 'react-twitter-widgets';
import { Col, Row } from 'antd/lib/';

class Justice extends Component {
  render() {
    return (
      <React.Fragment>
      <h3 className='quote'>"In order to make a free man a slave, all you have to do is convict them of a crime."</h3>
       <Row type="flex" justify="space-around" align="middle">
        <Col xs={{ span: 24}} md={{span: 6}}>
            <Tweet tweetId='1046490057522085888'/>
        </Col>
        <Col xs={{ span: 0}} md={{span: 6}}>
          <Tweet tweetId='1056728914289147904'/>
        </Col>
        <Col xs={{ span: 0}} md={{span: 6}}>
          <Tweet tweetId='1054279446692278272' />
        </Col>
        <Col xs={{ span: 0}} md={{span: 6}}>
          <Tweet tweetId='1046802196388446210'/>
        </Col>
       </Row>
       {/* <Row>
         <div className='videoContainer'>
           <iframe title='video3' width="560" height="315" src="https://www.youtube.com/embed/f0CVIGZVynA" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
         </div>
       </Row> */}
      </React.Fragment>

    )
  }
}

export default Justice;
