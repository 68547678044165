import React, { Component } from 'react';
import { Tweet } from 'react-twitter-widgets';
import './Action.css';
import {  Row, Col } from 'antd/lib/';

class Action extends Component {
  render() {
    return (
      <Row className='row-red-theme'>
        <Col span={12} offset={6}>
          <Tweet tweetId='1279575273365594112'/>
        </Col>
      </Row>
    );
  }
}

export default Action;
