import React, { Component } from 'react';
import { Tweet } from 'react-twitter-widgets';
import { Row, Col } from 'antd/lib/';

class HumanRights extends Component {
  render() {
    return (
      <React.Fragment>
       <h3 className='quote'>"No one is better than anyone."</h3>
       <Row type="flex" justify="space-around" align="middle">
        <Col xs={{ span: 24}} md={{span: 10}}>
          <Tweet tweetId='1057366623621955586' />
        </Col>
        <Col xs={{ span: 24}} md={{span: 10}}>
          <Tweet tweetId='1042779425048211456'/>
        </Col>
        <Col xs={{ span: 24}} md={{span: 10}}>
           <Tweet tweetId='1043549481319321600'/>
        </Col>
        <Col xs={{ span: 24}} md={{span: 10}}>
          <Tweet tweetId='1043675645182599170' />
        </Col>
       </Row>
      </React.Fragment>
    )
  }
}

export default HumanRights;
