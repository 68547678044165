import React, { Component } from 'react';
import './App.css';
import ReactGA from 'react-ga';
import MainLayout from './MainLayout.js';

const initializeReactGA = () => {
  ReactGA.initialize('UA-152752578-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}
initializeReactGA();

class App extends Component {
  render() {
    return (
            <MainLayout/>
    );
  }
}

export default App;
