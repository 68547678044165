import React, { Component } from 'react';
import { Tweet } from 'react-twitter-widgets';
import { Row, Col} from 'antd/lib/';
import './Supporters.css';
class Supporters extends Component {
  render() {
    return (
      <Row>
        <Col xs={{ span: 24, offset: 0 }} md={{span: 18, offset: 3}}>
          <h2 id='supporters'>Diverse Supporters</h2>
          <Row className='supporter-row' gutter={16}>
            <Col xs={{ span: 24}} md={{span: 12}}>
              <h3>Elon Musk <a href="https://twitter.com/intent/tweet"
                               className="twitter-mention-button"
                               data-size='large'
                               data-text='Support @ye4us in 2024 @elonmusk'
                               data-hashtags='ye4us,2024,kanyewest,uspresident'
                               data-show-count="false">Tweet to @elonmusk</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script></h3>
              <div className='videoContainer'>
                <iframe title='video20' width="560" height="315" src="https://www.youtube.com/embed/kW4fc7pm9gw" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
              </div>
            </Col>
            <Col xs={{ span: 24}} md={{span: 12}}>
              <h3>G Herbo <a href="https://twitter.com/intent/tweet"
                               className="twitter-mention-button"
                               data-size='large'
                               data-text='Support @ye4us in 2024 @gherbo'
                               data-hashtags='ye4us,2024,kanyewest,uspresident'
                               data-show-count="false">Tweet to @gherbo</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script></h3>
              <div className='videoContainer'>
                <iframe title='video21' width="560" height="315" src="https://www.youtube.com/embed/ALH-DyuZYmQ?start=462" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
          <Row className='supporter-row' gutter={16}>
          <Col xs={{ span: 24}} md={{span: 12}}>
              <h3>George Bush <a href="https://twitter.com/intent/tweet"
                               className="twitter-mention-button"
                               data-size='large'
                               data-text='Support @ye4us in 2024 @georgewbush'
                               data-hashtags='ye4us,2024,kanyewest,uspresident'
                               data-show-count="false">Tweet to @georgewbush</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script></h3>
              <div className='videoContainer'>
                <iframe title='video11' width="560" height="315" src="https://www.youtube.com/embed/gSsLHn-pYCw" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
              </div>
            </Col>
            <Col xs={{ span: 24}} md={{span: 12}}>
              <h3>Travis Barker <a href="https://twitter.com/intent/tweet"
                               className="twitter-mention-button"
                               data-size='large'
                               data-text='Support @ye4us in 2024 @travisbarker'
                               data-hashtags='ye4us,2024,kanyewest,uspresident'
                               data-show-count="false">Tweet to @travisbarker</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script></h3>
              <div className='videoContainer'>
                <iframe title='video9' width="560" height="315" src="https://www.youtube.com/embed/JgEl41IHqGA" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
          <Row className='supporter-row' gutter={16}>
            <Col xs={{ span: 24}} md={{span: 12}}>
              <h3>Donald Trump <a href="https://twitter.com/intent/tweet"
                               className="twitter-mention-button"
                               data-size='large'
                               data-text='Support @ye4us in 2024 @realdonaldtrump'
                               data-hashtags='ye4us,2024,kanyewest,uspresident'
                               data-show-count="false">Tweet to @realdonaldtrump</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script></h3>
              <div className='videoContainer'>
                <iframe title='video12' width="560" height="315" src="https://www.youtube.com/embed/jLmQ57mEGFs" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
              </div>
            </Col>
            <Col xs={{ span: 24}} md={{span: 12}}>
              <h3>Kris Jenner <a href="https://twitter.com/intent/tweet"
                               className="twitter-mention-button"
                               data-size='large'
                               data-text='Support @ye4us in 2024 @krisjenner'
                               data-hashtags='ye4us,2024,kanyewest,uspresident'
                               data-show-count="false">Tweet to @krisjenner</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script></h3>
              <div className='videoContainer'>
                <iframe title='video13' width="560" height="315" src="https://www.youtube.com/embed/H2ZPMO2Zuc4" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
          <Row className="supporter-row" gutter={16}>
            <Col xs={{ span: 24}} md={{span: 12}}>
              <h3>Kim Kardashian <a href="https://twitter.com/intent/tweet"
                               className="twitter-mention-button"
                               data-size='large'
                               data-text='Support @ye4us in 2024 @kimkardashian'
                               data-hashtags='ye4us,2024,kanyewest,uspresident'
                               data-show-count="false">Tweet to @kimkardashian</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script></h3>
              <Tweet tweetId='1279597399380332544'/>
            </Col>
            <Col xs={{ span: 24}} md={{span: 12}}>
              <h3>Mark Cuban <a href="https://twitter.com/intent/tweet"
                               className="twitter-mention-button"
                               data-size='large'
                               data-text='Support @ye4us in 2024 @mcuban'
                               data-hashtags='ye4us,2024,kanyewest,uspresident'
                               data-show-count="false">Tweet to @mcuban</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script></h3>
              <Tweet tweetId='1039220580279496704'/>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default Supporters;
