import React, { Component } from 'react';
import { Tweet } from 'react-twitter-widgets';
import { Row, Col } from 'antd/lib/';

class Environment extends Component {
  render() {
    return (
      <React.Fragment>
       <h3 className='quote'>"We have to operate as one eco system."</h3>
       <Row type="flex" justify="space-around" align="middle">
         <Col xs={{ span: 24}} md={{span: 6}}>
           <Tweet tweetId='1047479529810194432' />
         </Col>
         <Col xs={{ span: 24}} md={{span: 6}}>
           <Tweet tweetId='1047479214704799745' />
         </Col>
         <Col xs={{ span: 24}} md={{span: 6}}>
           <Tweet tweetId='1047479479495417858' />
         </Col>
       </Row>
      </React.Fragment>
    )
  }
}

export default Environment;
