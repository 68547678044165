import React, { Component } from 'react';
import './Campaign.css';
import {  Row, Col } from 'antd/lib/';

class Campaign extends Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} md={{span: 12, offset: 6}}>
            <h2 id="campaign" className='quote'>"A Trump Campaign with Bernie Sanders' Principles"</h2>
            <div className='videoContainer'>
              <iframe title='video6' width="560" height="315" src="https://www.youtube.com/embed/QiIUcsqtxcE" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
            </div>
          </Col>
        </Row>
        <Row className='row-dark-theme'>
          <Col xs={{ span: 22, offset: 1 }} md={{span: 12, offset: 6}}>
            <h2 className='quote'>"A Responsibility to Serve"</h2>
            <div className='videoContainer'>
              <iframe title='video7' width="560" height="315" src="https://www.youtube.com/embed/Thf-EELF0PE" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Campaign;
