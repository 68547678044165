import React, { Component } from 'react';
import './Issues.css';
import Justice from './issues/Justice.js';
import HumanRights from './issues/HumanRights.js';
import Education from './issues/Education.js';
import Jobs from './issues/Jobs.js';
import Love from './issues/Love.js';
import Environment from './issues/Environment.js';
import {  Row, Col, Collapse } from 'antd/lib/';
const Panel = Collapse.Panel;

class Issues extends Component {
  render() {
    return (
      <Row>
        <Col xs={{ span: 24, offset: 0 }} md={{span: 18, offset: 3}} >
          <Collapse accordion bordered={false}>
            <Panel header="Justice Reform" key="1">
              <Justice />
            </Panel>
            <Panel header="Human Rights" key="2">
              <HumanRights/>
            </Panel>
            <Panel header="Education" key="3">
              <Education />
            </Panel>
            <Panel header="Jobs" key="4">
              <Jobs />
            </Panel>
            <Panel header="Environment" key="5">
              <Environment />
            </Panel>
            <Panel header="Love" key="6">
              <Love />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    );
  }
}

export default Issues;
