import React, { Component } from 'react';
import { Row, Col, Carousel } from 'antd/lib/';
import { Tweet } from 'react-twitter-widgets';
import './Love.css';

class Love extends Component {
  render() {
    return (
      <Row>
        <Col xs={{ span: 24, offset: 0 }} md={{span: 8, offset: 2}} >
          <Tweet tweetId='990278445992108032'/>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} md={{span: 8, offset: 2}} >
          <Tweet tweetId='1047858661731708930'/>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} md={{span: 8, offset: 2}} >
          <Tweet tweetId='1040347073403596801'/>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} md={{span: 8, offset: 2}} >
          <Tweet tweetId='1039220580279496704'/>
        </Col>  
      </Row>
    )
  }
}

export default Love;
