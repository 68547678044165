import React, { Component } from 'react';
import { Tweet } from 'react-twitter-widgets';
import { Col, Row } from 'antd/lib/';

class Jobs extends Component {
  render() {
    return (
      <React.Fragment>
        <h3 className='quote'>"I support creating jobs and opportunities for people who need them the most."</h3>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 24}} md={{span: 10}}>
            <Tweet tweetId='1057366616432930816'/>
          </Col>
          <Col xs={{ span: 24}} md={{span: 10}}>
            <Tweet tweetId='1050921256923656192'/>
            
          </Col>
        </Row>
        <Row>
         <div className='videoContainer'>
           <iframe title='kanye4prez' width="560" height="315" src="https://www.youtube.com/embed/ihfG8qlhW04?start=1480" frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
         </div>
       </Row>
      </React.Fragment>
    )
  }
}

export default Jobs;
