import React, { Component } from 'react';
import { Tweet } from 'react-twitter-widgets';
import { Row, Col } from 'antd/lib/';

class Education extends Component {
  render() {
    return (
      <React.Fragment>
       <h3 className='quote'>"I'd rather teachers got paid more and books cost less."</h3>
       <Row type="flex" justify="space-around" align="middle">
       <Col xs={{ span: 24}} md={{span: 6}}>
          <Tweet tweetId='991466711583895553'/>
        </Col>
        <Col xs={{ span: 24}} md={{span: 6}}>
          <Tweet tweetId='2106830371'/>
        </Col>
        <Col xs={{ span: 24}} md={{span: 6}}>
          <Tweet tweetId='699926316028727296'/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="videoContainer">
            <iframe title='video1' width="560" height="315" src="https://www.youtube.com/embed/pRSjRFmN90E" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
          </div>
        </Col>
      </Row>
     </React.Fragment>
    )
  }
}

export default Education;
